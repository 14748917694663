<template>
  <div class="content">
    <ul class="content__list">
      <li
          class="content__list-item"
          v-show="!activeFilterToggled || (activeFilterToggled && activeIds[sectionId]?.includes(data.id))"
          data-layout-drop
          v-for="(data, sectionAlternativeIndex) in routerData"
          v-bind:key="sectionAlternativeIndex"
          v-on:drop="onDropSortSectionAlternative($event, sectionAlternativeIndex)"
          v-on:dragenter.prevent="dragEnterStyle($event)"
          v-on:dragover.prevent="dragEnterStyle($event)"
          v-on:dragleave.prevent="dragLeaveStyle($event)"
      >
        <div class="drag-tile -section" :class="{'-active' : activeIds[sectionId]?.includes(data.id)}" data-drag-item>
          <div class="drag-tile__flag" v-if="data.id === selectedSectionAlternative">
            <Icon name="flag" />
          </div>
          <div
              class="drag-tile__drag"
              draggable="true"
              v-on:dragstart="dragStartSectionAlternative($event, sectionAlternativeIndex)"
          >
            <Icon name="big-drag" v-bind:css-class="'-drag'"/>
          </div>
          <div class="drag-tile__content">
            <div class="drag-tile__header">
              <title-editor
                  :value="data?.name"
                  @edit-title="(value) => { editSectionAlternativeName(data.id, value)}"
              />
              <span class="drag-tile__length">({{data?.text_alternatives.length}})</span>
            </div>
            <div
                class="drag-tile__dropzone"
                data-signal-drop
                v-on:drop="onDropSignalSectionAlternative($event, sectionId, sectionAlternativeIndex)"
                v-on:dragenter.prevent="dragEnterStyle($event)"
                v-on:dragover.prevent="dragEnterStyle($event)"
                v-on:dragleave.prevent="dragLeaveStyle($event)"
            >
            <span
                class="drag-tile__signal"
                v-for="(selector, index) in data?.selectors"
                v-bind:key="index"
                v-bind:class="{'not' : selector.startsWith('not.')}"
            >
              <button
                  v-on:click="toggleSignalInSectionAlternative(selector, sectionId, sectionAlternativeIndex)"
              >
                {{selector}}
              </button>
              <button v-on:click="removeSignalFromSectionAlternative(selector, sectionId, sectionAlternativeIndex)">
                <Icon name="cancel" v-bind:css-class="'drag-tile__signal-icon'"/>
              </button>
            </span>
            </div>
            <div class="drag-tile__body">
              <div class="alternative-edit__text-edit">
                <p class="drag-tile__text unformatted-text" v-html="data?.text_alternatives.map(alternative => alternative.text).join('&nbsp;|&nbsp;')" />
                <div class="drag-tile__buttons">
                  <button class="button edit-button" @click="deleteSectionAlternative(data.id)">
                    <Icon name="delete-button" v-bind:css-class="'-edit'" />
                  </button>
                  <button class="button edit-button" @click="openEditModal(data)">
                    <Icon name="edit-button" v-bind:css-class="'-edit'" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="content__button">
      <button class="button" @click="appendSectionAlternative">
        <Icon name="add-button" v-bind:css-class="'-add'" />
      </button>
    </div>
  </div>
  <Modal
      v-bind:show-modal="modalOpen"
      v-bind:header-text="modalHeadline"
      @toggle-modal="toggleModal"
  >
    <template v-slot:body>
      <div class="alternative-edit">
        <div
            v-for="(alternative, index) in modalContent?.text_alternatives"
            v-bind:key="index"
        >
          <div v-bind:id="`text-edit-toolbar-${modalContent.id}${alternative.id}`" ></div>
          <text-edit-tiny-mce
              v-bind:active="index === activeItemIndex"
              v-bind:section-alternative-id="modalContent.id"
              v-bind:html-text="alternative"
              v-bind:signals="signalData"
              @change="changeTextAlternative"
              @delete="deleteTextAlternative" />
        </div>
        <div class="alternative-edit__button">
          <button class="button" @click="appendTextAlternative(modalContent.id, modalContent.text_alternatives.length)">
            <Icon name="add-button" v-bind:css-class="'-edit'" />
          </button>
        </div>
      </div>
    </template>
  </Modal>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";
import TitleEditor from "@/components/TitleEditor";
import TextEditTinyMce from '@/components/TextEditTinyMce';
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'SectionAlternativeListWidget',
  mixins: [DragDrop],
  components: {
    TextEditTinyMce,
    Icon,
    Modal,
    TitleEditor,
  },
  props: {
    customerId: {
      type: String,
    },
    sectionId: {
      type: String,
    },
    dataHandler: {
      type: Object,
    },
    routerData: {
      type: Array,
    },
    activeIds: {
      type: Object,
    },
    activeFilterToggled : {
      type: Boolean,
    },
    routingParams : {
      type: String,
    }
  },
  data() {
    return {
      draggingSortItem: null,
      modalOpen: false,
      modalHeadline: '',
      modalContent: null,
      deletionModalOpen: false,
      deletionId: null,
      editing: false,
      activeItemIndex: null,
    };
  },
  computed: {
    signalData() {
      return this.dataHandler.getSignals();
    },
    selectedSectionAlternative() {
      return this.dataHandler.getSelectedSectionAlternative(this.sectionId);
    }
  },
  methods: {
    setEditingState(value) {
      this.editing = value;
    },
    toggleModal() {
      this.$router.push({name:'Section', params: {
          customerId: this.customerId,
          sectionId: this.sectionId,
        }});
      this.modalOpen = false;
    },
    toggleSignalInSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
      this.dataHandler?.toggleSignalInSectionAlternative(signal, sectionId, sectionAlternativeIndex);
    },
    removeSignalFromSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
      this.dataHandler?.removeSignalFromSectionAlternative(signal, sectionId, sectionAlternativeIndex);
    },
    openEditModal(data) {
      this.modalOpen = !this.modalOpen;
      this.modalHeadline = data?.name;
      this.modalContent = data;
      this.activeItemIndex = data.text_alternatives.length - 1;
    },
    deleteSectionAlternative(sectionAlternativeId) {
      if (this.routerData.length > 1) {
        this.dataHandler?.deleteSectionAlternative(this.sectionId, sectionAlternativeId);
      } else {
        this.deletionId = sectionAlternativeId;
        this.deletionModalOpen = true;
      }
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteSectionAlternative(this.sectionId, this.deletionId);
      }
      this.deletionId = null;
      this.deletionModalOpen = false;
    },
    appendSectionAlternative() {
      this.dataHandler?.appendSectionAlternativeToSection(this.sectionId);
    },
    appendTextAlternative(sectionAlternativeId, latestItemIdx) {
      this.dataHandler?.appendTextAlternativeToSectionAlternative(this.sectionId, sectionAlternativeId);
      this.activeItemIndex = latestItemIdx;
    },
    changeTextAlternative(sectionAlternativeId, textAlternativeId, textAlternativeHTML) {
      this.dataHandler?.changeTextAlternative(this.sectionId, sectionAlternativeId, textAlternativeId, textAlternativeHTML);
    },
    deleteTextAlternative(sectionAlternativeId, textAlternativeId) {
      this.dataHandler?.deleteTextAlternative(this.sectionId, sectionAlternativeId, textAlternativeId);
    },
    editSectionAlternativeName(sectionAlternativeId, value) {
      this.dataHandler?.editSectionAlternativeName(this.sectionId, sectionAlternativeId, value);
    },
  },
  watch: {
    routingParams: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal !== oldVal && newVal) {
          const params = JSON.parse(newVal);
          if (params.linkedSectionAlternativeId) {
            const sectionAlternative = this.routerData?.find(sectionAlternative => sectionAlternative.id === parseInt(params.linkedSectionAlternativeId));

            if (sectionAlternative) {
              this.openEditModal(this.routerData?.find(sectionAlternative => sectionAlternative.id === parseInt(params.linkedSectionAlternativeId)));
            }
          }
          if (params.linkedTextAlternativeId) {
            const textAlternativeIndex = this.modalContent?.text_alternatives.findIndex((textAlternative) => textAlternative.id === parseInt(params.linkedTextAlternativeId));
            if (textAlternativeIndex !== -1) {
              this.activeItemIndex = textAlternativeIndex;
            }
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>

.alternative-edit {

  &__button {
    display: flex;
    margin-bottom: rem(30px);
    margin-top: rem(20px);

    button {
      margin-left: auto;
    }
  }

  &__text-edit {
    display: flex;
  }
}
</style>